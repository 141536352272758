import { Modal, ModalDialog } from "@mui/joy";
import Button from "../../../../../components/Button/Button";
import Countdown from "../../../../../components/CountDown/Countdown";
import gt from './../../../../../img/image for popup.png';
import OTPBox from "../../../../../components/OTPBox/OTPBox";
import { useMutation } from "react-query";
import { AuthService } from "../../../../../services/auth.service";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { replaceExceptLastTwo } from "../../../../../helpers/common.helper";

const CarOTPModal = (props) => {
    const idValue = useSelector(state => state.userData.id);
    const userSlice = useSelector(state => state.userData);

    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [dialogBtnDisabled, setDialogBtnDisabled] = useState(true);
    const [otpval, setOtpval] = useState('');
    const [otpError, setOtpError] = useState(false);

    const navigate = useNavigate();

    const handleResendClick = () => {
        // Add your logic here to resend the OTP
        resendOtpMutation(idValue)
        setIsResendDisabled(true)
    }
    const { mutate: resendOtpMutation } = useMutation(AuthService.resendOTP, {
        onSuccess: (data) => {
            console.log(data);
        },
        onError: (error) => {
            console.log(error)
        }
    });

    const { mutate: verifyOTPMutation } = useMutation(AuthService.verifyOTP, {
        onSuccess: (data) => {
            if (data.data) {
                if (props.isFormDisabled === true && props.formData.temp === false) {
                    navigate('/service-request/service')
                } else {
                    props.addCarsMutation(props.formData)
                }
            }
        },
        onError: (error) => {
            console.log(error);
            setOtpError(true)
        }
    });

    const dialogClose = () => {
        props.setNextBtnDisabled(false)
        props.setDialogOpen(false)
    }


    const saveCar = () => {
        setDialogBtnDisabled(true)
        verifyOTPMutation({
            id: userSlice.id,
            otp: otpval
        })
    }

    const countdownComplete = () => {
        setIsResendDisabled(false)
    }
    const handleOTPChange = (otp) => {
        setOtpval(otp)
        setOtpError(false)
        if (otp.length === 4) {
            setDialogBtnDisabled(false)
        } else {
            setDialogBtnDisabled(true)
        }
    }

    const maskedPhone = (userSlice.phone !== undefined || userSlice.phone !== "") ? replaceExceptLastTwo(userSlice.phone) : ""

    return (
        <Modal
            open={props.dialogOpen}
            onClose={dialogClose}
        >
            <ModalDialog>
                <div className="dialog-content">
                    <h2>Enter your OTP</h2>
                    <p>An OTP has been sent to your phone number</p>
                    {(maskedPhone !== "") ?
                        <p>An OTP has been sent to {maskedPhone}</p>
                        : ""}
                    <OTPBox otp={handleOTPChange} />
                    {(otpError === true) ?
                        <span className="error">Invalid OTP</span>
                        : ""}
                    <div>
                        <p className="resend">
                            Didn’t recieve a code ? <span
                                className={isResendDisabled ? 'disabled' : 'request'}
                                onClick={isResendDisabled ? null : handleResendClick}
                            >Request Again</span> {isResendDisabled && (
                                <span className="countdown-timer"><Countdown initialTime={60} onComplete={countdownComplete} /></span>
                            )}
                        </p>
                    </div>
                    <div className="button-cont">
                        <Button text='Cancel' variant={'border'} onClick={dialogClose} />
                        <Button text='Continue' onClick={saveCar} disabled={dialogBtnDisabled} />
                    </div>
                </div>
            </ModalDialog>
        </Modal>
    )
}

export default CarOTPModal;