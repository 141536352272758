import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary } from '@mui/joy';
import React, { useState } from 'react';
import { addService, deleteService } from '../../../../features/service/service.slice';
import { useDispatch, useSelector } from 'react-redux';

import {
    accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordianContent from './components/AccordianContent';

const ServiceTabs = (props) => {
    const { services, engineOrigin, engineSize } = props;
    const buyServices = useSelector(state => state.serviceData.selectedServices);
    const [aindex, setAIndex] = useState(0);
    const dispatch = useDispatch();

    const itemSelect = (mainService, subService, exist) => {
        if (exist) {
            dispatch(deleteService({
                mainService, subService
            }))
        } else {
            dispatch(addService({
                mainService, subService
            }))
        }
        props.callback(buyServices)
    }

    return (
        <div className="service-tabs">
            <AccordionGroup
                color="neutral"
                size="sm"
                variant="plain"
                sx={{
                    [`& .${accordionSummaryClasses.indicator}`]: {
                        transition: '0.2s',
                    },
                    [`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]: {
                        transform: 'rotate(180deg)',
                    },
                }}
            >
                {services.map((value, index) => (
                    <Accordion
                        expanded={aindex === index}
                        onChange={(event, expanded) => {
                            setAIndex(expanded ? index : null);
                        }}
                    >
                        <AccordionSummary indicator={<ExpandMoreIcon />}>{value.serviceName}</AccordionSummary>
                        <AccordionDetails>
                            <AccordianContent
                                services={services}
                                index={index}
                                engineOrigin={engineOrigin}
                                engineSize={engineSize}
                                buyServices={buyServices}
                                callback={(a, b, c) => itemSelect(a, b, c)}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </AccordionGroup>
        </div>
    );
}

export default ServiceTabs;

