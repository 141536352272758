import axios from "axios"

// Retrieve the API URL from environment variables
const url = process.env.REACT_APP_API_URL;

// Function to create a new user account
const createUser = (formData) => {
    return axios.post(url + "/auth/signup", {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phone
    });
}

// Function to perform a user login
const login = (formdata) => {
    return axios.post(url + "/auth/login", {
        "email": formdata.email,
        "password": formdata.password
    });
}

// Function to perform phone-based login
const loginPhone = (formdata) => {
    return axios.post(url + "/auth/phonelogin", {
        "phone": formdata.phone
    });
}

// Function to initiate the password reset process
const forgotPassword = (formdata) => {
    return axios.post(url + "/auth/forgotPassword", {
        "email": formdata.email
    });
}

// Function to resend OTP (One-Time Password) for verification
const resendOTP = (id) => {
    return axios.post(url + "/auth/resendOTP", {
        "id": id
    });
}

// Function to verify OTP during the login process
const verifyOTP = (formdata) => {
    return axios.post(url + "/auth/verifyOTP", {
        "id": formdata.id,
        "otp": formdata.otp
    });
}

// Function to set a new password
const setPassword = (formdata) => {
    return axios.post(url + "/auth/createPassword", {
        "password": formdata.password,
        "confirmPassword": formdata.confirmPassword
    }, {
        // Include an authorization token in the request headers
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

// Export all authentication-related functions as an AuthService object
export const AuthService = {
    createUser,
    login,
    verifyOTP,
    forgotPassword,
    setPassword,
    loginPhone,
    resendOTP
}
