import React, { useState } from 'react';

const ToggleComponent = (props) => {
    
    const handleTabClick = (tab) => {
        props.setActiveTab(tab);
    };

    return (
        <div className="ToggleComponent">
            <a className={props.activeTab === 'login' ? 'active' : ''} onClick={() => handleTabClick('login')}>Login</a>
            <a className={props.activeTab === 'signup' ? 'active' : ''} onClick={() => handleTabClick('signup')}>SignUp</a>
        </div>
    )
}

export default ToggleComponent;