
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import './payment.scss';
import { Modal, ModalDialog } from '@mui/joy';

import gt from '../../img/image for popup.png';

const PaymentFailedPage = () => {
    const navigate = useNavigate();
    const dialogOpen = true;
    return (
        <Modal
            open={dialogOpen}
        >
            <ModalDialog>
                <div className='payment'>
                    <h2>Payment failed</h2>
                    <img src={gt} alt="Car" />
                    <p>
                        Your payment has failed.
                    </p>
                    <Button variant={'border'} text='Back to Dashboard' disabled={false} onClick={(e) => navigate('/login/choose-car')} />
                </div>
            </ModalDialog>
        </Modal>

    )
}

export default PaymentFailedPage;