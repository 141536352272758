import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import validator from "validator";
import { AuthService } from "../../../services/auth.service";
import { useNavigate } from "react-router-dom";
import InputBox from "../../../components/InputBox/InputBox";
import Button from "../../../components/Button/Button";
import { useDispatch } from "react-redux";
import { setPasswordResetFlow, setUserData } from "../../../features/login/login.slice";

const ForgotPassword = () => {
    const [formData, setFormData] = useState({
        email: ""
    });
    const [errors, setErrors] = useState({});
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [formError, setFormError] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { mutate: forgotPasswordMutation, error: forgotError } = useMutation(AuthService.forgotPassword, {
        onSuccess: (data) => {
            dispatch(setUserData({
                _id: data.data.data,
                phoneNumber: data.data.phone
            }));
            dispatch(setPasswordResetFlow(true));
            navigate('/login/otp');
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const validateField = (name, value) => {
        let error = "";
        if (name === "email" && !validator.isEmail(value)) {
            error = "Invalid Email";
        }
        return error;
    };

    useEffect(() => {
        if (forgotError) {
            setFormError(forgotError.response.data.message);
        } else {
            setFormError("");
        }
    }, [forgotError])

    useEffect(() => {
        const isFormValid = Object.values(formData).every((value) => !validator.isEmpty(value) && validator.isEmail(value));
        if (isFormValid) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true)
        }
    }, [formData])

    const handleChange = (name, value) => {
        setFormError("");
        const error = validateField(name, value);
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setFormData((prevState) => ({ ...prevState, [name]: value }));

    };

    const loginHandler = () => {
        forgotPasswordMutation(formData)
    }

    const handleBack = () => {
        navigate('/login');
    }

    return (
        <div>
            <h3>Forgot Password</h3>
            <InputBox label='Email' placeholder="Enter your email" value={formData.email} onChange={(value) => handleChange("email", value)} error={errors.email} />
            <Button text='Send Verification' onClick={loginHandler} disabled={btnDisabled} />
            <Button variant={'border'} text='Back to Login' onClick={handleBack} />
        </div>
    )
}

export default ForgotPassword;