import axios from "axios"

// Retrieve the API URL from environment variables
const url = process.env.REACT_APP_API_URL;

// Function to get user data
const getUser = () => {
    return axios.get(url + "/user/me", {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

// Function to send an OTP (One-Time Password)
const sendOTP = () => {
    return axios.post(url + "/user/sendOTP", {}, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

// Function to update payment reference information
const updateReference = (formdata) => {
    return axios.post(url + "/payment/referenceUpdate", {
        "appointmentId": formdata.appointmentId,
        "paymentId": formdata.paymentId,
        "voucherCode": formdata.voucherCode,
        "voucherAmt": formdata.voucherAmt
    }, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

// Function to get car brands
const getBrands = () => {
    return axios.get(url + "/cars/", {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

// Function to get available services
const getServices = () => {
    return axios.get(url + "/services/", {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

// Function to get a calendar based on location code
const getCalendar = (locCode) => {
    return axios.get(url + "/calendar/" + locCode, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

// Function to add car information
const addCars = (formData) => {
    return axios.post(url + "/user/addCars", {
        "vehicleNumber": formData.vehicleNumber,
        "carBrand": formData.carBrand,
        "model": formData.carModel,
        "engineSize": formData.engineSize,
        "year": formData.year,
        "fuelType": formData.fuelType,
        "engineOrigin": formData.engineOrigin,
        "cname": formData.name,
        "cemail": formData.email,
        "vin": formData.vin
    }, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

// Function to add a new appointment
const addAppointment = (formData) => {
    return axios.post(url + "/appointment/add", {
        appointmentDate: formData.appointmentDate,
        appointmentTime: formData.appointmentTime,
        appointmentEndTime: formData.appointmentEndTime,
        customerNumber: formData.customerNumber.replace("+974", ""),
        locationCode: formData.locationCode,
        vehicleNumber: formData.vehicleNumber,
        customerId: formData.customerId,
        customerName: formData.customerName,
        services: formData.services,
        amount: formData.amount,
        comments: formData.comments,
        paylater: formData.paylater,
        vin: formData.vin
    }, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

// Function to initiate a payment
const initiatePayment = (amount) => {
    return axios.post(url + "/payment/initiate", {
        "InvoiceAmount": amount,
        "CurrencyIso": "KWD"
    }, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
}

// Function to create an invoice
const createInvoice = (formdata) => {
    return axios.post(url + "/payment/createInvoice", {
        "methodId": formdata.methodId,
        "appointmentId": formdata.appointmentId
    }, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
}

// Function to search for a license
const licenseSearch = (formdata) => {
    return axios.post(url + "/cars/licenseSearch", {
        "license": formdata.license,
    }, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
}

// Function to delete a car
const deleteCar = (formdata) => {
    return axios.delete(url + "/user/deleteCar/" + formdata.id, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
}

// Function to check a voucher
const checkVoucher = (formdata) => {
    return axios.get(url + "/user/checkVoucher/" + formdata.id, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
}

// Export all user-related functions as a UserService object
export const UserService = {
    getUser,
    getServices,
    getBrands,
    addCars,
    sendOTP,
    getCalendar,
    addAppointment,
    initiatePayment,
    createInvoice,
    licenseSearch,
    deleteCar,
    updateReference,
    checkVoucher
}
