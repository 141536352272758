// Import createSlice from Redux Toolkit
import { createSlice } from '@reduxjs/toolkit';

// Define a serviceSlice using createSlice
export const serviceSlice = createSlice({
    name: 'serviceData', // Name of the slice
    initialState: {
        selectedServices: {}, // Initial state with an empty object to store selected services,
        serviceFormData: {
            vehicleNumber: "",
            services: [],
            location: "",
            date: "",
            time: "",
            endtime: "",
            comments: ""
        }
    },
    reducers: {
        // Reducer to add or remove a service from selectedServices
        updateServiceFormData: (state, action) => {
            state.serviceFormData = action.payload;
        },
        addService: (state, action) => {
            // Extract data from the action payload
            const mainService = action.payload.mainService;
            const subService = action.payload.subService;

            // Extract relevant data from mainService and subService
            const mainServ = mainService.serviceName;
            const subServ = subService.subServiceName;
            const subServCode = subService.subServiceCode;

            // Create a copy of the current selectedServices object
            const prevstate = { ...state.selectedServices };
            const updatedServices = { ...prevstate };

            // Check if the main service exists in selectedServices
            if (!updatedServices[mainServ]) {
                updatedServices[mainServ] = [];
            }

            // Find the index of the subService within the main service's array
            const subServiceIndex = updatedServices[mainServ].findIndex(
                item => item.subServiceCode === subServCode
            );

            // If the subService is found, remove it; otherwise, add it
            if (subServiceIndex !== -1) {
                updatedServices[mainServ].splice(subServiceIndex, 1);
            } else {
                updatedServices[mainServ].push({
                    subService: subServ,
                    subServiceCode: subServCode,
                    price: subService.price
                });
            }

            // Update the selectedServices in the state
            state.selectedServices = updatedServices;
        },
        // Reducer to delete a service from selectedServices
        deleteService: (state, action) => {
            const mainService = action.payload.mainService;
            const subServiceCode = action.payload.subService.subServiceCode;
            const mainServ = mainService.serviceName;

            // Create copies of the current selectedServices object
            const prevstate = { ...state.selectedServices };
            const updatedServices = { ...prevstate };

            // Check if the main service exists in selectedServices
            if (updatedServices[mainServ]) {
                // Find the index of the subService with the provided subServiceCode
                const subServiceIndex = updatedServices[mainServ].findIndex(
                    item => item.subServiceCode === subServiceCode
                );

                // If the subService is found, remove it
                if (subServiceIndex !== -1) {
                    updatedServices[mainServ].splice(subServiceIndex, 1);

                    // If the main service's array is empty after removing the subService,
                    // remove the main service as well
                    if (updatedServices[mainServ].length === 0) {
                        delete updatedServices[mainServ];
                    }

                    // Update the selectedServices in the state
                    state.selectedServices = updatedServices;
                }
            }
        },
        clearServices: (state) => {
            return {
                ...state,
                selectedServices: {}
            };
        },
    }
});

// Export individual action creators for use in other parts of the application
export const {
    addService,
    deleteService,
    clearServices,
    updateServiceFormData
} = serviceSlice.actions;

// Export the reducer function to be used in the Redux store
export default serviceSlice.reducer;