import React, { useState } from 'react';
import './OTPBox.scss';

const OTPBox = (props) => {
    const digits = 4;
    const [otpValues, setOTPValues] = useState(Array(digits).fill(''));

    const handleInputChange = (index, value) => {
        // Only allow digits from 0 to 9
        if (/^[0-9]*$/.test(value)) {
            const newOTPValues = [...otpValues];
            newOTPValues[index] = value;

            if (value !== '') {
                if (index < digits - 1) {
                    // Move focus to the next input box
                    document.getElementById(`otp-input-${index + 1}`).focus();
                    props.otp(newOTPValues.join(''));
                } else {
                    // If last digit is entered, trigger the otp callback
                    props.otp(newOTPValues.join(''));
                }
            }else{
                props.otp(newOTPValues.join(''));
            }

            setOTPValues(newOTPValues);
        }
    };

    return (
        <div className='OTPBox'>
            {otpValues.map((value, index) => (
                <input
                    key={index}
                    id={`otp-input-${index}`}
                    type='text'  // Change type to 'text' to accept any characters
                    pattern="[0-9]*"  // Use HTML pattern attribute to further enforce digits
                    maxLength={1}  // Limit the input length to 1 character
                    value={value}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace' && value === '') {
                            // Move focus to the previous input box on Backspace
                            if (index > 0) {
                                document.getElementById(`otp-input-${index - 1}`).focus();
                            }
                        }
                    }}
                />
            ))}
        </div>
    );
};

export default OTPBox;