
import CircularProgress from '@mui/joy/CircularProgress';
import Input from '@mui/joy/Input';
import DiscountIcon from '@mui/icons-material/Discount';

import BookingOverview from './bookingOverview';
import { Modal, ModalDialog, Tooltip } from '@mui/joy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import XMLParser from 'react-xml-parser';

import Button from '../../../../components/Button/Button';
import { useMutation } from 'react-query';
import { UserService } from '../../../../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { setIncadeaAppointmentId, setSelectedAppointmentId, setSelectedPaymentMethod, setVoucher } from '../../../../features/login/login.slice';
import { useState } from 'react';
import { useEffect } from 'react';
import validator from "validator";
import { clearServices } from '../../../../features/service/service.slice';
import ProgressModal from './components/ProgressModal';
import BookingModal from './components/BookingModal';
import PaymentModal from './components/PaymentModal';

const BookingDetails = (props) => {

    const userSlice = useSelector(state => state.userData);
    const dispatch = useDispatch();
    const selectedCar = userSlice.cars.find((val) => val.vehicleNumber === userSlice.selectedLicense);
    const serviceSlice = useSelector(state => state.serviceData);

 
    const [payDisabled, setPayDisabled] = useState(true);
    const [paylaterProgress, setPaylaterProgress] = useState(false);
    const [promoDisabled, setPromoDisabled] = useState(false);
    const [showPromoInput, setShowPromoInput] = useState(true);
    const [promoError, setPromoError] = useState("");
    const [incadeaBookingID, setIncadeaBookingID] = useState("");
    const [promoCodeSuccess, setPromoCodeSuccess] = useState("");

    const [voucherCode, setVoucherCode] = useState("");
    const [servicesTotal, setServicesTotal] = useState("");

    const [paymentDialog, setPaymentDialog] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    
    const [totalAmt, setTotalAmt] = useState(true);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [payError, setPayError] = useState(false);

    const formData = props.formData;

    useEffect(() => {
        const isFormValid = Object.keys(formData).every((key) => {

            var value = formData[key];
            if (value === null || value === undefined) {
                return false;
            }
            if (Array.isArray(value)) {
                return value.length > 0; // Check if the array is not empty
            } else if (typeof value === 'object' && Object.keys(value).length > 0) {
                return true;
            } else if (typeof value === 'object' && Object.keys(value).length === 0) {
                return false;
            } else if (key === "comments") {
                return true;
            } else {
                return !validator.isEmpty(value); // Check if non-array values are not empty
            }
        });
        if (isFormValid) {
            setPayDisabled(false);
        } else {
            setPayDisabled(true)
        }
    }, [formData])

    const { mutate: appointmentMutation } = useMutation(UserService.addAppointment, {
        onSuccess: (data) => {
            var xml = new XMLParser().parseFromString(data.data.incadea);
            const incadeaId = xml.children[0].children[0].children[0].value;
            setIncadeaBookingID(incadeaId)
            setPaylaterProgress(false)
            setSuccessDialogOpen(true)
            dispatch(clearServices())
        },
        onError: (error) => {
            console.log(error);
            setPaylaterProgress(false)
            setPayError(error.response.data.message);
        }
    });

    

    const { mutate: initiatePaymentMutation } = useMutation(UserService.initiatePayment, {
        onSuccess: (data) => {
            setPaymentMethods(data.data.Data.PaymentMethods)
            setPaymentDialog(true);

        },
        onError: (error) => {
            console.log(error);
        }
    });

    useEffect(() => {
        let totalAmount = 0;
        const selectedServicesA = serviceSlice.selectedServices;
        // Iterate through each service array
        for (const serviceName in selectedServicesA) {
            if (selectedServicesA.hasOwnProperty(serviceName)) {
                const serviceArray = selectedServicesA[serviceName];

                // Iterate through the sub-services in the array and add up the prices
                for (const subService of serviceArray) {
                    totalAmount += subService.price;
                }
            }
        }
        const roundedNumber = formatToTwoDecimalPlaces(Math.round(totalAmount * 100) / 100);
        console.log(roundedNumber.toFixed(2))
        setTotalAmt(roundedNumber.toFixed(2));
        setServicesTotal(roundedNumber.toFixed(2));
        setShowPromoInput(true);
        setPromoCodeSuccess("")
        setVoucherCode("")
    }, [serviceSlice])


    const voucherCheck = () => {
        setPromoDisabled(true)

        const obj = {
            id: voucherCode
        }
        voucherCheckMutation(obj)
    }

    const handleCouponRemove = () => {
        setVoucherCode("");
        setPromoCodeSuccess("");
        setTotalAmt(formatToTwoDecimalPlaces(parseInt(servicesTotal)));
        dispatch(setVoucher({
            vouchercode: "",
            voucheramt: 0
        }))
        setShowPromoInput(true);
    }


    function formatToTwoDecimalPlaces(number) {
        if (typeof number !== 'number') {
            return NaN; // Return NaN if the input is not a number
        }
        return parseFloat(number.toFixed(2));
    }

    const { mutate: voucherCheckMutation } = useMutation(UserService.checkVoucher, {
        onSuccess: (data) => {
            const percent = data.data.data.percentage;
            const amt = formatToTwoDecimalPlaces(totalAmt * percent / 100);
            const finalAmt = totalAmt - amt;
            setPromoCodeSuccess("Applied promo code. You saved QAR " + amt.toFixed(2));
            const roundedNumber = Math.round(finalAmt * 100) / 100;
            setTotalAmt(roundedNumber.toFixed(2));
            setPromoDisabled(false);
            dispatch(setVoucher({
                vouchercode: voucherCode,
                voucheramt: amt
            }))
            setShowPromoInput(false);
        },
        onError: (error) => {
            console.log(error);
            setPromoError(error.response.data.message);
            setPromoDisabled(false)
        }
    });

    const payLater = () => {
        setPayError(false);
        setPaylaterProgress(true);
        const currentVehicle = userSlice.cars.find((val) => val.vehicleNumber === formData.vehicleNumber);
        let reqObj = {
            appointmentDate: formData.date,
            appointmentTime: formData.time,
            appointmentEndTime: formData.endtime,
            customerNumber: userSlice.phone.replace("+974", ""),
            locationCode: formData.location,
            vehicleNumber: formData.vehicleNumber,
            customerId: userSlice.id,
            customerName: userSlice.name,
            services: formData.services,
            amount: totalAmt,
            comments: formData.comments,
            paylater: true,
            vin: currentVehicle.vin
        }
        appointmentMutation(reqObj);
    }

    const payNow = () => {
        setPayError(false);
        setPayDisabled(true);
        initiatePaymentMutation(totalAmt);
    }
    const paymentCancelAction = () => {
        setPayDisabled(false);
        setPaymentDialog(false);

    }

    const handlerPromoCode = (e) => {
        setVoucherCode(e.target.value);
        setPromoError("");
    }
    

    

    const voucherKeyDown = (e) => {
        if (e.key === 'Enter' && !(promoDisabled || payDisabled)) {
            voucherCheck();
        }
    }
    return (
        <div>
            <div className='card'>
                <div>
                    Service Booking for
                    <h3>{selectedCar.carBrand} {selectedCar.model} - {selectedCar.year}, {selectedCar.vehicleNumber}</h3>
                </div>
                <BookingOverview services={serviceSlice.selectedServices} />
                <div className='coupon-cont'>
                    {(showPromoInput) ?
                        <div className='coupon'>
                            <Input className='promo-input' placeholder='Enter promo code' autofill="off" autoComplete="one-time-code"
                                onChange={(e) => handlerPromoCode(e)} id="outlined-basic" label="Enter promo code" variant="outlined"
                                startDecorator={<DiscountIcon />}
                                onKeyDown={voucherKeyDown}
                            />
                            <div>
                                <Button text='Apply' onClick={voucherCheck} disabled={promoDisabled || payDisabled} />
                            </div>
                        </div>
                        : <div className='coupon-applied'>
                            <div className='left-side'> <LocalOfferIcon /> <span>Coupon applied : {voucherCode}</span></div>
                            <div onClick={handleCouponRemove}>
                                <DeleteOutlineIcon />
                            </div>
                        </div>
                    }

                    {(promoError !== "") ? <span className='error'>{promoError}</span> : ""}
                    {(promoCodeSuccess !== "") ? <span className='success'>{promoCodeSuccess}</span> : ""}
                </div>
                <div className='estimate'>
                    <span className='label'>Estimated Amount </span>
                    <span className='icon'>
                        <Tooltip title="The actual cost can be subject to the service advisor's evaluation." size="md">
                            <HelpOutlineIcon />
                        </Tooltip>
                    </span>
                    <span className='right'> QAR {totalAmt}</span>
                </div>
                <div>
                    {(payError) ?
                        <div className='error'>{payError}</div>
                        : ""}
                </div>
                <div className='button-cont-serv'>
                    <Button variant={'border'} text='Pay later' onClick={payLater} disabled={payDisabled} />
                    <Button variant={'yellow'} text='Pay now' onClick={payNow}
                        disabled={payDisabled}
                    />
                </div>

            </div>
            <div className='mobile-button-cont-serv'>
                <Button variant={'border'} text='Pay later' onClick={payLater} disabled={payDisabled} />
                <Button variant={'yellow'} text='Pay now' onClick={payNow}
                    disabled={payDisabled}
                />
            </div>
            
            <BookingModal successDialogOpen={successDialogOpen} incadeaBookingID={incadeaBookingID} />

            <ProgressModal showProgress={paylaterProgress} />
            
            <PaymentModal 
                paymentDialog={paymentDialog}
                setPaymentDialog={setPaymentDialog}
                paymentCancelAction={paymentCancelAction}
                paymentMethods={paymentMethods}
                formData={formData}
                totalAmt={totalAmt}
                setPayError={setPayError}
                setPayDisabled={setPayDisabled}
            />
            
        </div>
    )
}

export default BookingDetails;