import { useState } from "react";
import AccordianContent from "./AccordianContent";
import { useDispatch, useSelector } from "react-redux";
import { addService, deleteService } from "../../../../../features/service/service.slice";

import { ReactComponent as Quicklube } from './../../../../../img/lube.svg';
import { ReactComponent as Computer } from './../../../../../img/CD.svg';
import { ReactComponent as Periodic } from './../../../../../img/PM.svg';

const DesktopServiceTabs = (props) => {
    const { services, engineOrigin, engineSize } = props;
    const buyServices = useSelector(state => state.serviceData.selectedServices);
    const [activeServiceIndex, setActiveServiceIndex] = useState(0);

    const dispatch = useDispatch();

    const itemSelect = (mainService, subService, exist) => {
        if (exist) {
            dispatch(deleteService({
                mainService, subService
            }))
        } else {
            dispatch(addService({
                mainService, subService
            }))
        }
        props.callback(buyServices)
    }

    console.log(services[activeServiceIndex])

    const IconSvg = (props) => {
        var ReturnComp = "";
        switch (props.service) {
            case "Quick Lube Service": ReturnComp = Quicklube; break;
            case "Periodic Maintenance Service": ReturnComp = Periodic; break;
            case "Computer Diagnostics": ReturnComp = Computer; break;
            default: ReturnComp = Computer; break;
        }
        return <ReturnComp />;
    }

    return (
        <div className="service-tabs">
            <div className="headers">
                {services.map((value, index) => (
                    <span
                        key={index}
                        onClick={() => setActiveServiceIndex(index)}
                        className={activeServiceIndex === index ? 'active' : ''}
                    > <IconSvg service={value.serviceName} /> {value.serviceName}</span>
                ))}
            </div>
            <div>
                {activeServiceIndex !== null && (
                    <AccordianContent
                        services={services}
                        index={activeServiceIndex}
                        engineOrigin={engineOrigin}
                        engineSize={engineSize}
                        buyServices={buyServices}
                        callback={(a, b, c) => itemSelect(a, b, c)}
                    />
                )}
            </div>
        </div>
    )
}

export default DesktopServiceTabs;