import { useEffect, useState } from 'react';

import Button from "../../../components/Button/Button";
import InputBox from "../../../components/InputBox/InputBox";
import ToggleComponent from "../ToggleComponent";
import { useNavigate } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import { AuthService } from '../../../services/auth.service';
import { useMutation } from 'react-query';
import validator from "validator";

const LoginSection = () => {
    const [activeTab, setActiveTab] = useState('login');
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [formError, setFormError] = useState("");

    const { mutate: loginMutation, error: loginError } = useMutation(AuthService.login, {
        onSuccess: (data) => {
            localStorage.setItem('token', data.data.token);
            navigate('/login/choose-car');
        },
        onError: (error) => {
            console.log(error);
        }
    });

    useEffect(() => {
        if (loginError) {
            console.log(loginError.response)
            if(loginError.response!==undefined){
                setFormError(loginError.response.data.message);
            }else{
                setFormError("Network error");
            }
        } else {
            setFormError("");
        }
    }, [loginError])

    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate("/login/choose-car")
        }
        document.title = 'AUTOLAB Qatar | Login';
    }, [])

    const validateField = (name, value) => {
        let error = "";
        if (name === "email" && !validator.isEmail(value)) {
            error = "Invalid Email";
        } else if (name === "password" && validator.isEmpty(value)) {
            error = "Invalid Password";
        }
        return error;
    };

    useEffect(() => {
        const isFormValid = Object.values(formData).every((value) => !validator.isEmpty(value));
        if (isFormValid) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true)
        }
    }, [formData])

    const handleChange = (name, value) => {
        setFormError("");
        const error = validateField(name, value);
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setFormData((prevState) => ({ ...prevState, [name]: value }));

    };

    const loginHandler = () => {
        const isFormValid = Object.values(formData).every((value) => !validator.isEmpty(value));
        const noErrorsFound = Object.values(errors).length === 2 && Object.values(errors).every((value) => validator.isEmpty(value));
        if (isFormValid && noErrorsFound) {
            loginMutation(formData)
        } else {
            console.log("error")
        }
    };

    const forgotpassword = () => {
        navigate('/login/forgot-password');
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !btnDisabled) {
            // If the Enter key is pressed and the button is not disabled, trigger form submission
            loginHandler();
        }
    }

    const handleBack = () => {
        navigate('/');
    }


    return (
        <div>
            <h3>Welcome Back</h3>
            <p>
                {(activeTab === "login")
                    ? "Enter the information you entered while registering."
                    : "Create an account and book your vehicle service now."}
            </p>
            <ToggleComponent activeTab={activeTab} setActiveTab={(e) => setActiveTab(e)} />
            {(activeTab === "login")
                ? <div>
                    <InputBox
                        label='Email' placeholder='Enter your email' value={formData.email}
                        onChange={(value) => handleChange("email", value)} error={errors.email}
                        onKeyDown={handleKeyDown}
                    />
                    <InputBox
                        label='Password' placeholder='Enter your password' type="password" value={formData.password}
                        onChange={(value) => handleChange("password", value)} error={errors.password}
                        onKeyDown={handleKeyDown}
                    />
                    {(formError === "") ? "" : <span className="error">{formError}</span>}
                    <span className='forgot-link' onClick={forgotpassword}>Forgot password?</span>
                    <Button text='Login' onClick={loginHandler} disabled={btnDisabled} />
                    <Button variant={'border'} text='Back to Homepage' onClick={handleBack} />
                </div>
                : <SignUpForm />
            }
        </div>
    )
}

export default LoginSection;