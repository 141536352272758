import { Checkbox, InputLabel, Popover, TextField } from '@mui/material';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ServiceTabs from './serviceTabs';
import { useMutation, useQuery } from 'react-query';
import { UserService } from '../../../../services/user.service';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import XMLParser from 'react-xml-parser';
import dateTimeJSON from '../../../../helpers/datetime.xml';
import moment from 'moment';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from 'react-router-dom';


import { addService, clearServices, deleteService } from '../../../../features/service/service.slice';
import ProgressModal from './components/ProgressModal';
import DesktopServiceTabs from './components/DesktopServiceTabs';



const ServiceDetails = (props) => {

    const userSlice = useSelector(state => state.userData);
    const dispatch = useDispatch();
    const selectedCar = userSlice.cars.find((val) => val.vehicleNumber === userSlice.selectedLicense);
    const serviceSlice = useSelector(state => state.serviceData);

    const { isLoading: dataLoading, data: servicesData, error: serviceError } = useQuery('getServicesDrop', UserService.getServices);

    const [allowedDates, setAllowedDates] = useState([]);
    const [formError, setFormError] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [calendarData, setCalendarData] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [payError, setPayError] = useState(false);
    const [showProgress, setShowProgress] = useState(false);

    var width = window.innerWidth;

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        vehicleNumber: userSlice.selectedLicense,
        services: [],
        location: "",
        date: "",
        time: "",
        endtime: "",
        comments: ""
    });
    useEffect(() => {
        if (dataLoading === false && servicesData === undefined) {
            navigate("/logout")
        }
    }, [dataLoading, serviceError, servicesData])

    const { mutate: calendarMutation } = useMutation(UserService.getCalendar, {
        onSuccess: (data) => {
            setCalendarData(data);
            setShowProgress(false);
        },
        onError: (error) => {
            setShowProgress(false);
            console.log(error);
        }
    });

    useEffect(() => {
        props.callback(formData);
    }, [formData])

    const handleChange = (name, value) => {
        setFormError("");
        setPayError(false);
        setFormData((prevState) => ({ ...prevState, [name]: value }));



    };

    const locationChange = (e, newItem) => {
        if (newItem !== null) {
            handleChange("location", newItem)
            handleChange("date", "")
            handleChange("time", "")
            setSelectedDate(null)
            setShowProgress(true);
            calendarMutation(newItem)

        }
    }

    useEffect(() => {
        handleChange("services", serviceSlice.selectedServices)
    }, [serviceSlice.selectedServices])

    const handleServiceChange = (data) => {
    }

    useEffect(() => {
        if (userSlice.selectedLicense === "" || userSlice.selectedLicense === undefined) {
            navigate("/login/choose-car");
        }
        document.title = 'AUTOLAB Qatar | Service Request';
    }, []);


    useEffect(() => {
        if (calendarData !== undefined && calendarData !== null) {
            var xml = new XMLParser().parseFromString(calendarData.data);
            const timeval = dateTimeJSON(xml);
            setAllowedDates(timeval)
            if (Object.keys(timeval).length > 0) {
                const dateKey = moment(Object.keys(timeval)[0]);
                setSelectedDate(dateKey);
                handleChange("date", dateKey.format("YYYY-MM-DD"))
                if (timeval[dateKey.format("YYYY-MM-DD")] !== undefined) {
                    handleChange("time", timeval[dateKey.format("YYYY-MM-DD")][0].startDate)
                    handleChange("endtime", timeval[dateKey.format("YYYY-MM-DD")][0].endDate)
                }
            }
        }
    }, [calendarData])

    const handleDateChange = (date) => {
        setSelectedDate(date);
        if (allowedDates[date.format("YYYY-MM-DD")][0] !== undefined) {
            handleChange("time", allowedDates[date.format("YYYY-MM-DD")][0].startDate)
            handleChange("endtime", allowedDates[date.format("YYYY-MM-DD")][0].endDate)
        }
        handleChange("date", date.format("YYYY-MM-DD"))
    };

    const isDateDisabled = (date) => {
        const dateString = moment(date).format('YYYY-MM-DD');
        if (Object.keys(allowedDates).includes(dateString)) {
            return false
        } else {
            return true
        }
    };




    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const popoverTimeSelect = (e, date, endDate) => {
        handleChange("time", date)
        handleChange("endtime", endDate)
        setAnchorEl(null);
    }



    const handleAdditionalCheck = (e) => {
        if (e.target.checked) {
            const splittedVal = e.target.value.split("|")
            dispatch(addService({
                mainService: { serviceName: "Additional services" },
                subService: {
                    subServiceName: splittedVal[0],
                    price: parseInt(splittedVal[1]),
                    subServiceCode: splittedVal[0],
                }
            }));
        } else {
            const splittedVal = e.target.value.split("|")
            dispatch(deleteService({
                mainService: { serviceName: "Additional services" },
                subService: {
                    subServiceName: splittedVal[0],
                    price: parseInt(splittedVal[1]),
                    subServiceCode: splittedVal[0],
                }
            }));
        }
    }

    const goBack = () => {
        dispatch(clearServices());
        navigate("/service-request/car")
    }

    if (dataLoading === true) {
        return "Loading"
    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>


                <div>
                    <div className='card'>
                        <h4 className='heading' onClick={goBack}> <ArrowBackIcon /> Select services <span className='mandatory'> *</span></h4>
                        {(width < 600) ?
                            <ServiceTabs
                                engineOrigin={selectedCar.engineOrigin}
                                engineSize={selectedCar.engineSize} services={servicesData.data.services} callback={handleServiceChange}
                            />
                            :
                            <DesktopServiceTabs
                                engineOrigin={selectedCar.engineOrigin}
                                engineSize={selectedCar.engineSize} services={servicesData.data.services} callback={handleServiceChange}
                            />
                        }
                    </div>
                    <div className='grid-50'>
                        <div className='card'>
                            <InputLabel id="">Location <span className='mandatory'>*</span></InputLabel>
                            <Select
                                id="demo-simple-select"
                                value={formData.location}
                                placeholder="Select location"
                                onChange={locationChange}
                                fullWidth
                            >
                                {servicesData.data.locations.map((value, index) => {
                                    return (
                                        <Option key={index} value={value.locationCode}>{value.locationName}</Option>
                                    )
                                })}

                            </Select>
                        </div>
                        <div className='card'>
                            {servicesData.data.additionalCharges.map((data, index) => {
                                return (
                                    <div>
                                        <Checkbox value={data.additionalChargeName + "|" + data.additionalCost} onChange={handleAdditionalCheck} id={data.additionalChargeName} /> {data.additionalChargeName}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='card'>
                        <div className='grid-50'>
                            <div>
                                <InputLabel id="">Date <span className='mandatory'>*</span></InputLabel>
                                <DatePicker
                                    label="Enter a date"
                                    onChange={handleDateChange}
                                    value={selectedDate}
                                    disabled={!formData.location}
                                    shouldDisableDate={isDateDisabled}
                                />
                            </div>
                            <div>
                                <InputLabel id="">Time <span className='mandatory'>*</span></InputLabel>

                                <div className='timePick' onClick={(e) => handleClick(e)} aria-describedby={id}>

                                    {(formData.time === "") ? "--:--" : moment(formData.time, "HH:mm:00").format("HH:mm A")}
                                </div>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    {(selectedDate) ?
                                        <div className='popover-time'>
                                            {allowedDates[selectedDate.format("YYYY-MM-DD")].map((data, index) => {
                                                return (
                                                    <div onClick={(e) => popoverTimeSelect(e, data.startDate, data.endDate)}>{moment(data.startDate, "HH:mm:00").format("HH:mm A")}</div>
                                                )
                                            })}
                                            {(allowedDates[selectedDate.format("YYYY-MM-DD")].length === 0) ? "No slots available" : ""}
                                        </div>
                                        : ""}
                                </Popover>
                            </div>
                        </div>

                    </div>
                    <div className='card'>
                        <h4>Comment</h4>
                        <TextField id="outlined-basic" fullWidth variant="outlined" value={formData.comments} onChange={(e) => handleChange("comments", e.target.value)} />
                    </div>
                </div>
                <ProgressModal showProgress={showProgress} />

            </LocalizationProvider>
        </div>
    )
}

export default ServiceDetails;