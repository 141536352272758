
import { Step, StepLabel, Stepper } from "@mui/material";
import './ServiceRequest.scss';
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const ServiceRequest = () => {
    const location = useLocation();
    const [routeState, setRouteState] = useState(0);
    useEffect(() => {
        // Use location.pathname to determine the current route
        const { pathname } = location;
        let newState = 0;
        if (pathname === '/service-request/car') {
            newState = 1;
        } else if (pathname === '/service-request/service') {
            newState = 2;
        }

        setRouteState(newState);
    }, [location]);
    return (
        <div>
            <div className="progress-cont">
                <div className="progress card">
                    <Stepper activeStep={routeState}>
                        <Step>
                            <StepLabel></StepLabel>
                        </Step>
                        <Step>
                            <StepLabel></StepLabel>
                        </Step>
                        <Step>
                            <StepLabel></StepLabel>
                        </Step>
                    </Stepper>
                </div>
            </div>
            <div className="form-cont">
                <Outlet />
            </div>
        </div>
    )
}

export default ServiceRequest;