import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from "react-error-boundary";

import LoginPage from "./pages/LoginPage/LoginPage";
import HomePage from "./pages/HomePage/HomePage";
import OTPSection from "./pages/LoginPage/sections/OTPSection";
import LoginSection from "./pages/LoginPage/sections/LoginSection";
import ChoosePassword from "./pages/LoginPage/sections/ChoosePassword";
import ServiceRequest from "./pages/ServiceRequest/ServiceRequest";
import ChooseCar from "./pages/LoginPage/sections/chooseCar";
import CarDetails from "./pages/ServiceRequest/components/car-details/car-details";
import ServiceDetails from "./pages/ServiceRequest/components/service-details/service-details";
import LogoutPage from "./pages/logout/logout.page";
import ForgotPassword from "./pages/LoginPage/sections/forgotPassword";
import PaymentPage from "./pages/payment/payment";
import PhoneLogin from "./pages/LoginPage/sections/phoneLogin";
import PaymentFailedPage from "./pages/payment/paymentError";
import ServiceParent from "./pages/ServiceRequest/components/service-details/serviceParent";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import NoMatch from "./pages/NoMatch/noMatch";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
});

function AppRouter() {
  return (
    <div className="AppRouter">
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary fallback={<ErrorPage />}>

          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />}>
                <Route path="/login/" element={<LoginSection />} />
                <Route path="/login/otp" element={<OTPSection />} />
                <Route path="/login/password" element={<ChoosePassword />} />
                <Route path="/login/choose-car" element={<ChooseCar />} />
                <Route path="/login/forgot-password" element={<ForgotPassword />} />
                <Route path="/login/phone" element={<PhoneLogin />} />
              </Route>
              <Route path="/service-request" element={<ServiceRequest />}>
                <Route path="/service-request/car" element={<CarDetails />} />
                <Route path="/service-request/service" element={<ServiceParent />} />
                <Route path="/service-request/payment-success" element={<PaymentPage />} />
                <Route path="/service-request/payment-failed" element={<PaymentFailedPage />} />
              </Route>
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </Router>
        </ErrorBoundary>
      </QueryClientProvider>
    </div>
  );
}

export default AppRouter;