import React from 'react';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';

import './index.scss';

import AppRouter from './AppRouter';
import { persistor, store } from './store';
import { Provider } from 'react-redux';
import CircularProgress from '@mui/joy/CircularProgress';
import TagManager from 'react-gtm-module'

const root = ReactDOM.createRoot(document.getElementById('root'));
const tagManagerArgs = {
  gtmId: 'GTM-T43LZTSN'
}

TagManager.initialize(tagManagerArgs)


window.dataLayer = window.dataLayer || [];

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={
          <div><CircularProgress
            color="primary"
            determinate={false}
            size="md"
            variant="soft"
          /></div>}
        persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
