import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import validator from "validator";

import Button from "../../../components/Button/Button";
import InputBox from "../../../components/InputBox/InputBox";
import { useMutation } from "react-query";
import { AuthService } from "../../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../../features/login/login.slice";

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const SignUpForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });
    const [errors, setErrors] = useState({});
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [formError, setFormError] = useState("");

    const { mutate: createUserMutation, data: createUserSuccess, error: createUserError } = useMutation(AuthService.createUser, {
        onSuccess: (data) => {
            dispatch(setUserData(data.data.data));
            navigate('/login/otp')
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const validateField = (name, value) => {
        if (value === undefined) {
            value = "";
        }
        let error = "";
        if (name === "name" && validator.isEmpty(value)) {
            error = "Name cannot be empty";
        } else if (name === "email" && !validator.isEmail(value)) {
            error = "Invalid Email";
        } else if ((name === "phone" && validator.isEmpty(value))) {
            error = "Phone cannot be empty"
        } else if ((name === "phone" && value.length > 12)) {
            error = "Invalid phonenumber"
        } else if ((name === "phone" && !value.includes("+974"))) {
            error = "Only Qatar numbers allowed"
        }
        return error;
    };

    const handleChange = (name, value) => {
        setFormError("");
        if (value === undefined) {
            value = "";
        }
        const error = validateField(name, value);
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        const isEmailValid = validator.isEmail(formData.email);
        const isFormValid = Object.values(formData).every((value) => !validator.isEmpty(value)) && isEmailValid;
        if (isFormValid) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true)
        }
    }, [formData])

    useEffect(() => {
        if (createUserError) {
            setFormError(createUserError.response.data.message);
        } else {
            setFormError("");
        }
    }, [createUserError])

    const createHandler = () => {
        const isFormValid = Object.values(formData).every((value) => !validator.isEmpty(value));
        const noErrorsFound = Object.values(errors).length === 3 && Object.values(errors).every((value) => validator.isEmpty(value));
        if (isFormValid && noErrorsFound) {
            createUserMutation(formData)
        } else {
            console.log("error")
            setFormError("error. please check the data")
        }
    };

    const handleBack = () => {
        navigate('/');
    }

    return (
        <div>
            <InputBox label='Name' placeholder="Enter your name" value={formData.name} onChange={(value) => handleChange("name", value)} error={errors.name} />
            <InputBox label='Email' placeholder="Enter your email" value={formData.email} onChange={(value) => handleChange("email", value)} error={errors.email} />
            <PhoneInput
                placeholder="Enter phone number"
                value={(formData.phone) ? formData.phone : ""}
                countries={['QA']}
                defaultCountry={"QA"}
                addInternationalOption={false}
                international="false"
                onChange={(value) => handleChange("phone", value)} />
            <span className="error">{errors.phone}</span>
            {(formError === "") ? "" : <span className="error">{formError}</span>}
            <Button text='Create Account' onClick={createHandler} disabled={btnDisabled} />
            <Button variant={'border'} text='Back to Homepage' onClick={handleBack} />
        </div>
    )
}

export default SignUpForm;