// Import the necessary styles for the Button component
import './Button.scss';

// Import PropTypes to define the expected props types
import PropTypes from 'prop-types';

// Define a functional component called "Button" that accepts props
const Button = (props) => {
    // Create a CSS class for the button element based on the variant prop
    let buttonClass = `Button ${props.variant}`;

    // Add the "disabled" class if the "disabled" prop is set to true
    if (props.disabled) {
        buttonClass += " disabled";
    }

    // Define a click event handler for the button
    const handleClick = (e) => {
        // Check if the "onClick" prop is defined and the button is not disabled
        if ('onClick' in props && props.disabled !== true) {
            // Call the onClick callback with the target value
            props.onClick(e.target.value)
        }
    }

    // Render the button element with the appropriate classes and text
    return (
        <div className={buttonClass} onClick={handleClick}>
            {props.text}
        </div>
    )
}

// Define default prop values for the Button component
Button.defaultProps = {
    text: "Button",       // Default text is "Button"
    variant: 'yellow',    // Default variant is 'yellow'
    disabled: false       // Default disabled state is false
}

// Define the expected prop types for the Button component
Button.propTypes = {
    text: PropTypes.string.isRequired,             // Requires a string for "text" prop
    variant: PropTypes.oneOf(['yellow', 'border']), // Requires one of the specified values for "variant"
    onClick: PropTypes.func,                        // "onClick" should be a function
    disabled: PropTypes.bool                        // "disabled" should be a boolean
}

// Export the Button component as the default export
export default Button;